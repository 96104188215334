// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-galleria-jsx": () => import("./../../../src/pages/galleria.jsx" /* webpackChunkName: "component---src-pages-galleria-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-jalkahoito-jsx": () => import("./../../../src/pages/jalkahoito.jsx" /* webpackChunkName: "component---src-pages-jalkahoito-jsx" */),
  "component---src-pages-kynnet-jsx": () => import("./../../../src/pages/kynnet.jsx" /* webpackChunkName: "component---src-pages-kynnet-jsx" */),
  "component---src-pages-parturi-kampaamo-jsx": () => import("./../../../src/pages/parturi-kampaamo.jsx" /* webpackChunkName: "component---src-pages-parturi-kampaamo-jsx" */),
  "component---src-pages-yhteystiedot-jsx": () => import("./../../../src/pages/yhteystiedot.jsx" /* webpackChunkName: "component---src-pages-yhteystiedot-jsx" */)
}

